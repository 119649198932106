export default class Footer {
    constructor() {
        Footer.partners()
    }
    static partners() {
        $('[data-partner]').on('mouseenter', function () {
            const src = $(this).find('img').attr('src')
            $(this).find('img').attr('src', src.replace('.svg', '.webp'))
        })

        $('[data-partner]').on('mouseleave', function () {
            const src = $(this).find('img').attr('src')
            $(this).find('img').attr('src', src.replace('.webp', '.svg'))
        })
    }
}
