import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'

export default class Header {
    constructor() {
        Header.swiper_banner()
        Header.sticky()
        Header.submenu()
        Header.keyboard()
        Header.gtranslate()
        Header.live()

        if (window.matchMedia('(max-width: 1000px)').matches) {
            Header.burger()
        }
    }

    static async swiper_banner() {
        const swiperItem = '[data-swiper="banner"]'
        const slideItem = '[data-swiper="banner"] > div'

        const options = {
            slidesPerView: 'auto',
            autoplay: {
                delay: 3000,
            },
            speed: 1000,
            effect: 'fade',
            fadeEffect: {
                crossFade: true,
            },
            pagination: {
                el: '#swiper-banner-pagination',
                type: 'bullets',
                clickable: true,
            },
            navigation: {
                nextEl: '#swiper-banner-next',
                prevEl: '#swiper-banner-prev',
            },
            mousewheel: {
                forceToAxis: true,
            },
            grabCursor: true,
            loop: true,
        }

        $(swiperItem).append(
            '<span id="swiper-banner-prev" class="hc-slider-button-prev"></span><span id="swiper-banner-next" class="hc-slider-button-next"></span><span id="swiper-banner-pagination" class="hc-slider-pagination"></span>',
        )
        $(slideItem).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide')

        await Swiper.create('[data-swiper="banner"]', options)
    }

    static sticky() {
        let last_scroll_top = 0

        $(window).on('load scroll', function () {
            const offset = 180 //Trigger offset (~header height)
            const navbar = $('[data-id="wpiris-navbar"]')
            const scroll_top = $(this).scrollTop()
            let full = false

            //Hidden comportment
            if (
                scroll_top >= last_scroll_top &&
                scroll_top > navbar.outerHeight() &&
                scroll_top > offset
            ) {
                $('body').addClass('header-is-hidden')
            } else {
                $('body').removeClass('header-is-hidden')
            }

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                full = true
            }

            last_scroll_top = scroll_top

            if (scroll_top > offset) {
                navbar.removeClass('main-navigation--ontop')
                navbar.addClass('main-navigation--onscroll')
            } else if (scroll_top < offset - 45) {
                // prevent header height difference
                navbar.removeClass('main-navigation--onscroll')
                navbar.addClass('main-navigation--ontop')
            }
        })
    }

    static submenu() {
        const container = '[data-id="submenu-block"]'
        const list = '[data-id="submenu-list"]'
        const trigger = '[data-trigger="submenu-list"]'
        const zoneTrigger = '[data-zone-id]'
        let zoneActive = 1

        $(trigger).click(function () {
            const zone = $(this).closest(container).data('zone-trigger')
            if (zone) {
                selectZone(zone)
            } else {
                $(this).closest(list).find(container).removeClass('is-active')
                $(this).closest(container).addClass('is-active')
            }
        })

        $(zoneTrigger).click(function () {
            selectZone($(this).data('zone-id'))
        })

        function selectZone(zone) {
            if (zone !== zoneActive) {
                $(`[data-zone-id="${zoneActive}"]`).removeClass('is-active')
                $(`[data-zone-trigger="${zoneActive}"]`).removeClass('is-active')
                $(`[data-zone-id="${zone}"]`).addClass('is-active')
                $(`[data-zone-trigger="${zone}"]`).addClass('is-active')
                zoneActive = zone
            } else {
                location.href = $(`[data-zone-trigger="${zoneActive}"] [data-zone-href]`).attr(
                    'href',
                )
            }
        }
    }

    static keyboard() {
        const menuItem = '[data-trigger="wpiris-menu-more"]'
        const menuLv3 = '.menu__link__lv2'

        $(menuItem).keydown(function (e) {
            const ev = e || event
            if (ev.keyCode === 13) {
                $('.menu__item').removeClass('is-hover')
                $(this).closest('.menu__item').toggleClass('is-hover')
                return false
            }
            if (ev.keyCode === 27) {
                $('.menu__item').removeClass('is-hover')
            }
        })

        $(menuLv3).keydown(function (e) {
            const ev = e || event
            if (ev.keyCode === 13) {
                $(this).siblings('[data-trigger="submenu-list"]').trigger('click')
                return false
            }
        })
    }

    static burger() {
        const trigger = '[data-trigger="wpiris-menu-toggle"]'
        const more = '[data-trigger="wpiris-menu-more"]'
        const prev = '[data-trigger="wpiris-menu-prev"]'
        const submenu = '[data-trigger="wpiris-menu-submenu"]'

        $(trigger).on('click', () => {
            $('body').toggleClass('menu-open')
        })

        $(more).on('click', function (e) {
            e.preventDefault()
            $(this).next(submenu).toggleClass('submenu-open')
            $('body').toggleClass('submenu-open')
        })

        $(prev).on('click', (e) => {
            e.preventDefault()
            $(submenu).removeClass('submenu-open')
            $('body').removeClass('submenu-open')
        })
    }

    static live() {
        const target = '[data-id="wpiris-live-detail"]'
        const live = '[data-id="wpiris-live"]'

        $(live).on('click', (e) => {
            if ($(e.target).closest(target).length) {
                if (!$(e.target).closest(target).hasClass('is-active')) {
                    $(target).removeClass('is-active')
                    $(e.target).closest(target).addClass('is-active')
                } else {
                    $(e.target).closest(target).removeClass('is-active')
                }
            } else {
                $(target).removeClass('is-active')
            }
        })

        $('[data-trigger="wpiris-modal"][href="#live"]').on('click', (e) => {
            $('body').toggleClass('live-is-open')
        })
    }

    static gtranslate() {
        const current_lang = '[data-id="wpiris_current-lang"]'
        const list_lang_item = '[data-id="wpiris_lang-list"] > a'

        //Init select lang
        $(window).on('load', () => {
            setTimeout(() => {
                const current_language = $('html').attr('lang').replace(/-.*/gi, '')
                $(current_lang).text(current_language.toUpperCase()).text()

                //Remove current language on list (init)
                $(list_lang_item).each(function () {
                    if ($(this).text() === current_language.toUpperCase()) {
                        $(this).hide()
                    }
                })
            }, 300)
        })

        //Change Navbar Language on click
        $(list_lang_item).on('click', function () {
            $(list_lang_item).show()
            $(this).hide()
            $(current_lang).text($(this).text())
        })
    }
}
