export default class Utils {
    constructor() {
        Utils.strate_scroll()
        Utils.toggle_modal()
        Utils.live_hour()
    }

    static toggle_modal() {
        const trigger = '[data-trigger="wpiris-modal"]'

        $(document).on('click', trigger, function (e) {
            e.preventDefault()
            const target = $(this).attr('href') ? $(this).attr('href') : $(this).attr('data-href')
            $(target).toggleClass('is-open')
        })
    }

    static live_hour() {
        const $element = $("[data-id='wpiris-live-hour']")

        setInterval(() => {
            const date = new Date()
            let h = date.getHours()
            let m = date.getMinutes()

            if (h < 10) h = `0${h}`
            if (m < 10) m = `0${m}`

            $element.html(`${h}:${m}`)
        }, 1000)
    }

    static strate_scroll() {
        const trigger = '[data-trigger="strate-scroll"]'

        $(document).on('click', trigger, (e) => {
            e.preventDefault()

            const duration = 1000

            let scroll_top = $('#strate-destination').offset().top

            if (window.matchMedia('(max-width: 1000px)').matches) {
                scroll_top = $('#strate-agenda').offset().top
            }

            $('html, body').animate(
                {
                    scrollTop: scroll_top,
                },
                {
                    duration: duration,
                    step: (now, fx) => {
                        const real_pos = scroll_top
                        if (fx.end !== real_pos) {
                            fx.end = real_pos
                        }
                    },
                },
            )
        })
    }
}
